@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');

$brand: #00A6CE;
$action: #5CD6CE;
$border: #E2E5E9;
$text: #29393D;
$white: #FFFFFF;

:root {
  --color-white: #FFFFFF;
  --color-text: #29393D;
  --color-brand: #00A6CE;
  --color-action: #5CD6CE;
  --color-background-one: #E2F2FD;
  --color-background-two: #DBF6F4;
  --color-background-three: #DBF6E4;
  --color-border: #E2E5E9;
  --color-green: #47D780;
}

// App
body, html {
  background: #fff;
  color: var(--color-text);
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  margin: 0;
  height: 100%;
}
body.no-scroll {
  overflow-y: scroll; /* Разрешает видимость полосы прокрутки */
  position: fixed; /* Запрещает прокрутку страницы */
  width: 100%; /* Фиксирует ширину страницы */
  top: calc(-1 * var(--scroll-position)); /* Запоминает место прокрутки */
}
title {
  display: block;
  font-weight: 600;
  font-size: 24px;
  color: $brand;
  margin: 32px 0;
}

h2 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: bold;
  color: $text;
}

h3 {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  color: $text;
}

// content
.content {
  max-width: 1160px;
  margin: auto;
  padding: 0 15px 0px 15px;
}

.caption {
  font-size: 12px;
}

.color-success {
  color: var(--color-green);
}